import React, { useState, useContext, useRef } from 'react'
import styled from 'styled-components'
import Icon from './Icon'
import { DictionaryContext } from '../contexts'
import soundSrc from '../assets/messageSound.mp3'
import { parseContent } from '../utils'
import {
  mobileVW,
  desktopVW,
  desktopBreakpoint,
  color,
  underlineMultiLines
} from '../styles'
import { expo } from '../styles/eases'

const ReviewsChat = ({ reviews, product }) => {
  const dictionary = useContext(DictionaryContext)
  const productsPerLoad = 1
  const [limit, setLimit] = useState(productsPerLoad)
  const audioRef = useRef()

  const loadMore = async () => {
    await audioRef.current.play()
    setLimit(limit + productsPerLoad)
  }

  return (
    <>
      {(reviews && reviews.length > 0) && (
        <StyledReviewsChat product={product} id='reviews'>
          <ReviewsCount>
            {reviews.length}<br />{dictionary.reviews}
          </ReviewsCount>
          <ReviewsWrapper>
            <ReviewBubble className='from-superfluid' visible>
              <Content>{dictionary.reviewsChatMessage}</Content>
            </ReviewBubble>
            {reviews.length && reviews.map(({ user, score, title, content, created_at, images_data, comment }, index) => {
              return (
                <React.Fragment key={index}>
                  <ReviewBubble className='from-customer' visible={index < limit}>
                    <UserInfo>
                      <IconWrapper score={score}>
                        <Icon name='star' />
                        <Icon name='star' />
                        <Icon name='star' />
                        <Icon name='star' />
                        <Icon name='star' />
                      </IconWrapper>
                      <span>{user.display_name}</span>
                      <span>{created_at.split('T')[0]}</span>
                    </UserInfo>
                    <Title>{parseContent(title)}</Title>
                    <Content>{parseContent(content)}</Content>
                  </ReviewBubble>
                  {images_data && (
                    <>
                      {images_data.map(({ thumb_url }, index) => (
                        <ReviewBubble key={index} className='from-customer review-image' visible>
                          <img src={thumb_url} alt='reviews image' />
                        </ReviewBubble>
                      ))}
                    </>
                  )}
                  {comment && (
                    <ReviewBubble className='from-superfluid' visible>
                      <Content>{parseContent(comment.content)}</Content>
                    </ReviewBubble>
                  )}
                </React.Fragment>
              )
            })}
            <ReviewBubble
              type='button'
              ariaLabel='load-more'
              className='from-superfluid load-more'
              visible={reviews.length > limit}
              onClick={() => loadMore()}
            >
              <Content>load more</Content>
            </ReviewBubble>
          </ReviewsWrapper>
          <audio ref={audioRef}><source src={soundSrc} type='audio/mpeg' /></audio>
        </StyledReviewsChat>
      )}
    </>
  )
}

const StyledReviewsChat = styled.div`
  position: relative;
  min-height: ${({ product }) => product ? mobileVW(500) : '100vh'};
  width: 100%;
  overflow-x: hidden;
  padding: ${({ product }) => product ? `${mobileVW(60)} ${mobileVW(20)} 0` : `${mobileVW(120)} ${mobileVW(20)} 0`};

  @media (min-width: ${desktopBreakpoint}) {
    min-height: ${({ product }) => product ? desktopVW(800) : '100vh'};
    padding: ${desktopVW(200)} ${desktopVW(60)} 0;
  }
`

const ReviewsCount = styled.h1`
  font-size: ${mobileVW(65)};
  line-height: ${mobileVW(55)};
  padding-bottom: ${mobileVW(30)};
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    width: calc(100% - ${desktopVW(120)});
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: ${desktopVW(200)};
    line-height: ${desktopVW(169)};
    padding-bottom: 0;
  }
`

const ReviewsWrapper = styled.div`
  width: 100%;
`

const ReviewBubble = styled.div`
  word-wrap: break-word;
  max-width: 100%;
  width: fit-content;
  margin-bottom: ${mobileVW(20)};
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(18)};
  padding: ${mobileVW(10)} ${mobileVW(20)};
  position: relative;
  border-radius: 25px;
  color: ${color.white}; 
  
  &:before, &:after {
    content: '';
		position: absolute;
    bottom: -2px;
    height: 20px;
  }

  &.from-customer {
    background: #087FFF;
    align-self: flex-end;
    margin-left: auto;
    transition: transform 1s ${expo.out};
    margin-bottom: ${({ visible }) => visible ? mobileVW(20) : mobileVW(-20)};
    height: ${({ visible }) => visible ? 'auto' : '0'};
    transform: ${({ visible }) =>
      visible ? 'translate3d(0,0,0)' : 'translate3d(200%, 0, 0)'};
  
      
    &:before {
      right: -7px;
      border-right: 20px solid #087FFF;
      border-bottom-left-radius: 16px 14px;
      transform: translate(0, -2px);
    }
  
    &:after {
      right: -56px;
      width: 26px;
      background: ${color.white};
      border-top: solid ${color.white};
      border-bottom-left-radius: 10px;
      transform: translate(-30px, -2px);
    }
  }

  &.from-superfluid {
    background: #26DA4E;
    text-transform: uppercase;
    height: ${({ visible }) => visible ? 'auto' : '0'};
    transform: ${({ visible }) =>
      visible ? 'translate3d(0,0,0)' : 'translate3d(-200%, 0, 0)'};
      
    &:before {
      left: -7px;
      border-left: 20px solid #26DA4E;
      border-bottom-right-radius: 16px 14px;
      transform: translate(0, -2px);
    }
  
    &:after {
      left: 4px;
      width: 26px;
      border-top: solid ${color.white};
      background: ${color.white};
      border-bottom-right-radius: 10px;
      transform: translate(-30px, -2px);
    }

    p {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &.review-image {
    padding: 0;
    border-radius: 25px;
    max-height: ${mobileVW(120)};
    max-width: ${mobileVW(135)};

    &:before, &:after {
      display: none;
    }

    img {
      max-height: ${mobileVW(120)};
      max-width: ${mobileVW(135)};
    }
  }

  &.load-more {
    cursor: pointer;
    visibility: ${({ hide }) => hide ? 'hidden' : 'inherit'};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    p {
      ${underlineMultiLines}
      font-weight: bold;
      background-image: linear-gradient(${color.white}, ${color.white});
    } 
  }

  @media (min-width: ${desktopBreakpoint}) {
    max-width: ${desktopVW(532)};
    margin-bottom: ${desktopVW(20)};
    font-size: ${desktopVW(15)};
    line-height: ${desktopVW(24)};
    padding: ${desktopVW(10)} ${desktopVW(20)};

    &.from-customer {
      margin-bottom: ${({ visible }) => visible ? desktopVW(20) : desktopVW(-20)};
    }  

    &.review-image {
      max-height: ${desktopVW(120)};
      max-width: ${desktopVW(135)};
  
      img {
        max-height: ${desktopVW(120)};
        max-width: ${desktopVW(135)};
      }
    }
  }
`

const UserInfo = styled.div`
  display: flex;
  height: ${mobileVW(18)};

  span {
    margin-right: ${mobileVW(10)};
    font-weight: bold;
  }

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVW(24)};
    span {
      margin-right: ${desktopVW(10)};
    }
  }
`

const IconWrapper = styled.div`
  display: flex;
  width: ${mobileVW(40)};
  margin-right: ${mobileVW(10)};

  svg:first-child {
    visibility: ${({ score }) => score >= 1 ? 'visible' : 'hidden'};
  }
  svg:nth-child(2) {
    visibility: ${({ score }) => score >= 2 ? 'visible' : 'hidden'};
  }
  svg:nth-child(3) {
    visibility: ${({ score }) => score >= 3 ? 'visible' : 'hidden'};
  }
  svg:nth-child(4) {
    visibility: ${({ score }) => score >= 4 ? 'visible' : 'hidden'};
  }
  svg:nth-child(5) {
    visibility: ${({ score }) => score >= 5 ? 'visible' : 'hidden'};
  }
  
  svg {
    fill: ${color.white};
    width: ${mobileVW(8)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVW(40)};
    margin-right: ${desktopVW(10)};

    svg {
      width: ${desktopVW(8)};
    }
  }
`

const Title = styled.p`
  font-weight: bold;
  text-transform: initial;
`

const Content = styled.p`
  text-transform: initial;
  display: inline-block;
`

export default ReviewsChat
