import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import ReviewsFilters from '../components/reviews/ReviewsFilters'
import ReviewsChat from '../components/ReviewsChat'

const Reviews = ({
  data: {
    contentfulReviewsPage: {
      slug,
      seoTitle,
      seoDescription
    }
  }
}) => {
  return (
    <>
      <SEO slug={slug} title={seoTitle} description={seoDescription} isPage />
      {/* <ReviewsFilters /> */}
      <ReviewsChat reviews={[]} />
    </>
  )
}

export const PageQuery = graphql`
  query Reviews($node_locale: String!) {
    contentfulReviewsPage(node_locale: { eq: $node_locale }) {
      slug
      seoTitle
      seoDescription
    }
  }
`

export default Reviews
