import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { setFilterBoxOpen } from '../../actions'
import { DictionaryContext } from '../../contexts'
import {
  mobileVW,
  desktopVW,
  desktopBreakpoint,
  underline,
  zIndex,
  color,
  border,
  headerHeight
} from '../../styles'

const ReviewsFilters = () => {
  const dictionary = useContext(DictionaryContext)
  const dispatch = useDispatch()
  const filterBoxOpen = useSelector(({ visible }) => visible.filterBoxOpen)
  const [selectedFilters, setSelectedFilters] = useState([])

  return (
    <StyledReviewsFilters>
      <Filter onClick={() => dispatch(setFilterBoxOpen(true))}>
        {dictionary.filterBy}
      </Filter>
      <Filters
        filterBoxOpen={filterBoxOpen}
        onClick={() => dispatch(setFilterBoxOpen(true))}
      >
        {/* {categoriesFilter.length > 0 ||
          applyOnsFilter.length > 0 ||
          colorsFilter.length > 0 ? (
            <span>
              {categoriesFilter.length > 0 ? dictionary.category : null}
              {categoriesFilter.length > 0 &&
              (applyOnsFilter.length || colorsFilter.length)
                ? ', '
                : ' '}
              {applyOnsFilter.length > 0 ? dictionary.applyOn : null}
              {applyOnsFilter.length > 0 && colorsFilter.length ? ', ' : ' '}
              {colorsFilter.length > 0 ? dictionary.color : null}
            </span>
          ) : (
            <>{dictionary.select}</>
          )} */}
      </Filters>
      {/* {filterBoxOpen && (
          <SelectionWrapper>
            <Overlay onClick={() => dispatch(setFilterBoxOpen(false))} />
            <Selections>
              <FilterWrapper>
                <FilterBy aria-label='filter-by-category'>
                  {dictionary.category}
                </FilterBy>
                <FiltersList>
                  {productCategories.map(({ name }, index) => (
                    <Care
                      key={index}
                      aria-label='color-category'
                      selected={categoriesFilter.includes(name)}
                      onClick={() => {
                        categoriesFilter.includes(name)
                          ? dispatch(
                            setCategoriesFilter(
                              categoriesFilter.filter((x) => x !== name)
                            )
                          )
                          : dispatch(
                            setCategoriesFilter([
                              ...categoriesFilter,
                              name
                            ])
                          )
                      }}
                      className={
                        categoriesFilter.includes(name)
                          ? 'is-selected'
                          : null
                      }
                    >
                      {name}
                    </Care>
                  ))}
                </FiltersList>
              </FilterWrapper>

              <FilterWrapper>
                <FilterBy aria-label='filter-by-applyOn'>
                  {dictionary.applyOn}
                </FilterBy>
                <FiltersList>
                  {productApplyOns.map(({ name }, index) => (
                    <Care
                      key={index}
                      aria-label='color-applyOn'
                      selected={applyOnsFilter.includes(name)}
                      onClick={() => {
                        applyOnsFilter.includes(name)
                          ? dispatch(
                            setCaresFilter(
                              applyOnsFilter.filter((x) => x !== name)
                            )
                          )
                          : dispatch(
                            setCaresFilter([...applyOnsFilter, name])
                          )
                      }}
                      className={
                        applyOnsFilter.includes(name) ? 'is-selected' : null
                      }
                    >
                      {name}
                    </Care>
                  ))}
                </FiltersList>
              </FilterWrapper>

               <FilterWrapper>
                <FilterBy aria-label='filter-by-color'>
                  {dictionary.color}
                </FilterBy>
                <FiltersList>
                  {productColors.map(({ hexCode }, index) => (
                    <Color
                      key={index}
                      aria-label='color-filter'
                      bgColor={hexCode}
                      selected={colorsFilter.includes(hexCode)}
                      onClick={() => {
                        colorsFilter.includes(hexCode)
                          ? dispatch(
                              setColorsFilter(
                                colorsFilter.filter(x => x !== hexCode),
                              ),
                            )
                          : dispatch(
                              setColorsFilter([...colorsFilter, hexCode]),
                            )
                      }}
                    />
                  ))}
                </FiltersList>
              </FilterWrapper>

              <StyledButton
                onClick={() => {
                  dispatch(setFilterBoxOpen(false))
                }}
              >
                {dictionary.apply}
              </StyledButton>
            </Selections>
          </SelectionWrapper>
        )} */}

    </StyledReviewsFilters>
  )
}

const StyledReviewsFilters = styled.div`
  position: sticky;
  top: ${headerHeight.mobile};
  width: 100%;
  height: ${mobileVW(76)};
  z-index: ${zIndex.medium};
  background-color: ${color.white};
  padding: ${mobileVW(20)};

  .hide-on-mobile {
    display: none;
  }

  @media (min-width: ${desktopBreakpoint}) {
    position: absolute;
    left: 0;
    top: 0;
    margin-top: -${desktopVW(90)};
    padding-top: ${desktopVW(90)};
    bottom: calc(100% - ${desktopVW(420)});
    width: ${desktopVW(361)};
    height: auto;

    .hide-on-mobile {
      display: flex;
    }

    .hide-on-desktop {
      display: none;
    }
  }
`

const Filter = styled.p``

const Filters = styled.div`
  &:after {
    position: relative;
    margin-left: ${mobileVW(6)};
    font-size: ${mobileVW(12)};
    font-weight: bold;
    ${({ filterBoxOpen }) => (filterBoxOpen ? "content: '-'" : "content: '+'")};
  }
`

const SelectionWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const Overlay = styled.div`
  flex: 1 1 auto;
  background: rgba(0, 0, 0, 0.1);
`

const Selections = styled.div`
  position: relative;
  flex: 0 2 ${mobileVW(180)};
  left: 0;
  bottom: 0;
  background: ${color.white};
  width: 100%;
  border-top: ${border.medium};
  z-index: ${zIndex.medium - 5};

  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
    border-top: none;
  }
`

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${border.thin};
  height: ${mobileVW(66)};
  font-size: ${mobileVW(10)};
  line-height: ${mobileVW(8)};
  padding: 0 ${mobileVW(32)};
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    font-size: ${desktopVW(10)};
    line-height: ${desktopVW(8)};
    padding: 0 0 ${desktopVW(16)} 0;
    height: auto;
    width: 100%;
    border-bottom: none;
  }
`

const FilterBy = styled.button`
  padding-right: ${desktopVW(20)};
  font-weight: normal;
  white-space: nowrap;

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 0 ${desktopVW(15)} 0;
  }
`

const FiltersList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(20)};

  @media (min-width: ${desktopBreakpoint}) {
    justify-content: flex-start;

    &.filters-list-apply-ons,
    &.filters-list-categories {
      flex-direction: column;
    }
  }
`

// const Color = styled.button`
//   width: 16px;
//   height: 16px;
//   border-radius: 50%;
//   position: relative;
//   background-color: ${({ bgColor }) => bgColor};
//   ${({ selected, bgColor }) =>
//     selected && darkColorDetector(bgColor)
//       ? `border: 1px solid ${color.darkGrey};`
//       : selected
//       ? `border: ${border.medium};`
//       : lightColorDetector(bgColor)
//       ? `border: 1px solid ${color.lightGrey};`
//       : null}
//   ${({ selected }) => (selected ? `margin-right: ${mobileVW(5)};` : null)}

//     @media (min-width: ${desktopBreakpoint}) {
//       ${({ selected }) => (selected ? `margin-right: ${desktopVW(4)};` : null)}
//     }
// `

const Care = styled.button`
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(16)};
  text-transform: uppercase;

  &.is-selected {
    ${underline.static}
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(16)};
    line-height: ${desktopVW(16)};
    text-align: left;
    margin-bottom: ${desktopVW(5)};
    width: fit-content;

    ${underline.hover}

    &.is-selected {
      &:after {
        transform: scale(1);
      }
    }
  }
`

const StyledButton = styled.button`
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(10)};
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  height: ${mobileVW(65)};
  justify-content: center;
  align-items: center;
  width: calc(100% - ${mobileVW(64)});
  cursor: pointer;
  position: relative;
  bottom: 0;
  margin: ${mobileVW(32)};
  background-color: ${color.black};
  color: ${color.white};
`

const Description = styled.p`
  @media (min-width: ${desktopBreakpoint}) {
    text-transform: initial;
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(19)};
    padding-right: ${desktopVW(45)};
    margin-bottom: ${desktopVW(16)};
  }
`

// const FreeShipping = styled.div`
//   @media (min-width: ${desktopBreakpoint}) {
//     font-size: ${desktopVW(12)};
//     line-height: ${desktopVW(19)};
//     padding-right: ${desktopVW(60)};
//     font-weight: bold;
//     position: relative;

//     span {
//       background: ${color.yellow};
//       padding: 0 ${desktopVW(5)};
//     }
//   }
// `

export default ReviewsFilters
